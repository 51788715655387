import React, { useState , linkState } from 'react';
import { withRouter } from "react-router-dom";
import { ACCESS_TOKEN_NAME } from '../../js/constants/apiConstants';
import { NavDropdown, Row, Col, Image, Form, FormControl, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import logo from '../../assets/images/logo_app_header.png';
import { IoArrowBack, IoHomeOutline } from 'react-icons/io5';
import { setSearchString, setGoBack } from "../../js/actions/index";

const mapStateToProps = state => {
    return {
      searchString: state.searchString,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setSearchString, setGoBack }, dispatch)
}

function Header(props) {

    const { t, i18n } = useTranslation('common');
    const [searchStringTemp, setSearchStringTemp] = useState(null)

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
    let title = capitalize(props.location.pathname.substring(1,props.location.pathname.length))
    if(props.location.pathname === '/') {
        title = 'UD Speaker'
    }

    function renderLogout() {
        if(props.location.pathname !== '/login'){
            return(
                <div className="align-right">
                    <button className="btn btn-outline-primary" onClick={() => handleLogout()}>{t('app.logout')}</button>
                </div>
            )
        } else {
            return (
                <div className="align-right"></div>
            )
        }
    }

    function renterTitle() {
        if (props.location.pathname !== '/main') {
            return (
                <span className="h4">{props.title || title}</span>
            )
        } else {
            return (
                <div className="header-icons">
                    <IoHomeOutline className='top-icon' onClick={changeLocales} />
                    <IoArrowBack className='top-icon' onClick={goBack}/>
                </div>
            )
        }
    }

    function renderIcons() {
        if (['/main', '/search'].includes(props.location.pathname) ) {
            return (
                <div className='mr-2'>
                    <Form inline onSubmit={e => { e.preventDefault(); handleSearchSubmit(); }}>
                        <Form.Control value={searchStringTemp} type="text" placeholder={t('app.search')} className="mr-sm-2" onChange={handleSearchString}/>
                        <Button onClick={handleSearchSubmit} variant="outline-success">{t('app.search')}</Button>
                    </Form>
                </div>
            )
        }
    }

    const setUILocale = (eventKey) => i18n.changeLanguage(eventKey);
    function renderUILocaleSelector() {
        return (
            <div className='mr-2 hide' id='lang-selector'>
                <NavDropdown title={t('app.language')} id="nav-dropdown" onSelect={setUILocale}>
                    <NavDropdown.Item eventKey="es">Español</NavDropdown.Item>
                    <NavDropdown.Item eventKey="en">English</NavDropdown.Item>
                </NavDropdown>
            </div>
        )
    }

    function handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN_NAME)
        props.history.push('/')
    }

    function changeLocales() {
        props.history.push('/home')
    }

    function goBack(){
        props.setGoBack(true)
    }

    function handleSearchSubmit(){
        if (searchStringTemp.length > 2){
            props.setSearchString(searchStringTemp)
            setSearchStringTemp('')
            props.history.push('/search')            
        }
    }
    
    function handleSearchString(e) {
        setSearchStringTemp(e.target.value)
    }

    return(
        <nav className="navbar navbar-light mb-5">
            <Row className="w-100">
                <Col xs={5}>
                    {renterTitle()}
                </Col>
                <Col xs={2} className="text-center">
                    <img src={logo} alt="Logo" id="logoUD" />
                </Col>
                <Col xs={5} id="header-sidebar-area">
                    {renderIcons()}
                    {renderUILocaleSelector()}
                    {renderLogout()}
                </Col>
            </Row>
        </nav>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
