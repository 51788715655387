export const SET_LOCALE_HCW = "SET_LOCALE_HCW";
export const SET_LOCALE_PATIENT = "SET_LOCALE_PATIENT";
export const SET_ALTERNATE = "SET_ALTERNATE";
export const SET_CURRENT_SPEAKER = "SET_CURRENT_SPEAKER";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const SET_CURRENT_NODE_ID = "SET_CURRENT_NODE_ID";
export const SET_ACTOR1_NODE_ID = "SET_ACTOR1_NODE_ID";
export const SET_ACTOR2_NODE_ID = "SET_ACTOR2_NODE_ID";
export const SET_FORCE_CHANGE_SPEAKER = "SET_FORCE_CHANGE_SPEAKER";
export const SET_GO_BACK = "SET_GO_BACK";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_APPTREE = "SET_APPTREE";