import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { API_NODE, API_AUDIO, ACCESS_TOKEN_NAME, API_TREE } from '../../js/constants/apiConstants';
import { LOADING_STATUS_INIT, LOADING_STATUS_START, LOADING_STATUS_STARTED, LOADING_STATUS_ENDED, LOADING_STATUS_ERROR } from '../../js/constants/apiConstants';
import { CURRENT_SPEAKER_HCW, CURRENT_SPEAKER_PATIENT } from '../../js/constants/appConstants';
import NodeTree from '../NodeTree/NodeTree';
import axios from 'axios'
import { Card, Row, Col, Spinner} from 'react-bootstrap';
import ReactPlayer from 'react-player'
import { bindActionCreators } from 'redux';
import { setCurrentNodeId, setActor1NodeId, setActor2NodeId, setCurrentSpeaker, setForceChangeSpeaker, setGoBack, setErrorMessage } from "../../js/actions/index";
import { useTranslation } from "react-i18next";

const mapStateToProps = state => {
	return {
        currentSpeaker: state.currentSpeaker,
		localeHcw: state.localeHcw,
		localePatient: state.localePatient,
		alternate: state.alternate,
		currentNodeId: state.currentNodeId,
		actor1NodeId: state.actor1NodeId,
		actor2NodeId: state.actor2NodeId,
		forceChangeSpeaker: state.forceChangeSpeaker,
		goBack: state.goBack,
		appTree: state.appTree,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setCurrentNodeId, setActor1NodeId, setActor2NodeId, setCurrentSpeaker, setForceChangeSpeaker, setGoBack, setErrorMessage}, dispatch)
}

function Main(props) {
	const { t, i18n  } = useTranslation('common');
	const [loadingStatus, setLoadingStatus] = useState(LOADING_STATUS_INIT)
	const [sentences, setSentences] = useState([])
  	const [stackNodes, setStackNodes] = useState([])
	const [nodeTitle, setNodeTitle] = useState('')
	const [nodePlayURL, setnodePlayURL] = useState(null)
	const [step, setStep] = useState(0)
	const [nextNode, setNextNode] = useState(null)

	//console.log(props.localePatient + ' for patient; ' + props.localeHcw + ' for hcw')
	//i18n.changeLanguage('ru_RU');

	useEffect(() => {
		if (props.forceChangeSpeaker){
			// console.log('entro a force Change Speaker')
			props.setForceChangeSpeaker(false)
			switchFooterSpeaker()
			if (props.alternate && !nextNode){
				// when they force manually to change the speaker despite is automatic
				props.setCurrentNodeId(null);
			}else{
				if (nextNode){
					// pull node if the node is not a leaf (nextNode is null)
					pullNode(true, nextNode)
					setNextNode(null)
				}
			}
		}
		if (props.goBack){
			// console.log('entro a goBack')
			props.setGoBack(false)
			goBack()
		}
		if (!props.currentNodeId && props.actor1NodeId && loadingStatus === LOADING_STATUS_ENDED){
			// console.log('entro a !props.currentNodeId && props.actor1NodeId')
			if (props.currentSpeaker === CURRENT_SPEAKER_HCW){
				props.setCurrentNodeId(props.actor1NodeId)
			}else{
				props.setCurrentNodeId(props.actor2NodeId)
			}
			setNodeTitle('')
			setStackNodes([])
			setStep(2)
			setLoadingStatus(LOADING_STATUS_START)
		}
		if (step === 1 && loadingStatus === LOADING_STATUS_ENDED && props.currentNodeId){
			// specific case when it arrives from search
			// it could be needed to refactor after step 1 has been moved to localeselector
			console.log('entro a step == 1 && loadingStatus === LOADING_STATUS_ENDED && props.currentNodeId')
			setNodeTitle('')
			setStackNodes([])
			setStep(2)
			setLoadingStatus(LOADING_STATUS_START)
		}
		if (step === 1 && loadingStatus === LOADING_STATUS_START){
			// console.log('entro a step == 1 && loadingStatus === LOADING_STATUS_START')
			setLoadingStatus(LOADING_STATUS_STARTED)
			var url = process.env.REACT_APP_API_URL + API_TREE + '?apptree=' + props.appTree
			var config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }
			axios.get(url, config)
				.then(function (response) {
					setLoadingStatus(LOADING_STATUS_ENDED)
					if (response.status === 200) { 
						props.setActor1NodeId(parseInt(response.data.data.actor1_initial_node))
						props.setActor2NodeId(parseInt(response.data.data.actor2_initial_node))
					}else{
						setLoadingStatus(LOADING_STATUS_ERROR)
					}
				})
				.catch(function (error) {
					setLoadingStatus(LOADING_STATUS_ERROR)
					props.setErrorMessage(t('api.generic_error'))
					// console.log('error' + error)
				});
		}
		if (step === 2 && loadingStatus === LOADING_STATUS_START){
			// console.log('entro a step == 2 && loadingStatus === LOADING_STATUS_START')
			setLoadingStatus(LOADING_STATUS_STARTED)
			var url = process.env.REACT_APP_API_URL + API_NODE 
				+ '?node-id=' + props.currentNodeId
				+ '&locale-code-1=' + props.localeHcw
				+ '&locale-code-2=' + props.localePatient
				+ '&levels=2'
			var config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }
			axios.get(url, config)
				.then(function (response) {
					if (response.status === 200) { 
						// console.log(response.data.data)
						setSentences(response.data.data.descendants)
						var title = response.data.data.sentence.locale1.value ?? ''
						if (props.currentSpeaker !== CURRENT_SPEAKER_HCW){
							title = response.data.data.sentence.locale2.value ?? ''
						}
						setNodeTitle(title)
						setLoadingStatus(LOADING_STATUS_ENDED)
					}else{
						setLoadingStatus(LOADING_STATUS_ERROR)
					}
				})
				.catch(function (error) {
					setLoadingStatus(LOADING_STATUS_ERROR)
					props.setErrorMessage(t('api.generic_error'))
				});
		}
	})

	if (!props.localeHcw || !props.localePatient ){
		props.history.push('/home')
		return null
	}else{
		if (step === 0 && loadingStatus === LOADING_STATUS_INIT){
			setStep(step + 1)
			setLoadingStatus(LOADING_STATUS_START)
		}
	}

	function changeNode(newId, isLeaf, isAlternateSpeakerNode, sentenceIdToPlay = null){

		// Play a sentence automatically
		if (sentenceIdToPlay != null) playSentence(sentenceIdToPlay)

		if (isLeaf) return;

		// when is needed to change the speaker but autoalternate is not set
		//   we store the nextId to use it when they click to change manually
		if (isAlternateSpeakerNode){
			setNextNode(newId)
			if (props.alternate){
				props.setForceChangeSpeaker(true)
			}
		}else{
			// deep into the tree without changing speaker
			pullNode(isAlternateSpeakerNode, newId)
		}
	}

	function pullNode(isAlternateSpeakerNode, newId){
		stackNodes.push([props.currentNodeId, isAlternateSpeakerNode])	
		props.setCurrentNodeId(newId)
		setNodeTitle('')
		setLoadingStatus(LOADING_STATUS_START)
	}

	function goBack(){
		if (stackNodes.length > 0){
			var previousNode = stackNodes.pop()
			if (previousNode[1]){
				// not possible to use setForceChangeSpeaker because it push nodes into the stack
				switchFooterSpeaker()
			}
			setNodeTitle('')
			setLoadingStatus(LOADING_STATUS_START)
			props.setCurrentNodeId(previousNode[0])
		}
	}

	function switchFooterSpeaker(){
		var newSpeaker = CURRENT_SPEAKER_HCW
		if (props.currentSpeaker === CURRENT_SPEAKER_HCW){
			newSpeaker = CURRENT_SPEAKER_PATIENT
		}
		props.setCurrentSpeaker(newSpeaker);
	}

	function playSentence(localeSentenceId){
		var theURL = process.env.REACT_APP_AUDIOS_URL + API_AUDIO + '?localeSentenceId=' + localeSentenceId
		console.log(theURL)
		setnodePlayURL(theURL)
	}
  return (
    <div className="mt-2">
		<Card className='card-standard transparent'>
			<Card.Body>
				{ nodeTitle &&
					<Card.Title className="mb-4 text-center">
						<strong>{nodeTitle}</strong>
					</Card.Title>
				}	
				<Card.Text>
					{ step === 2 && loadingStatus === LOADING_STATUS_ENDED ?
						sentences.map((sentence) => (
						<NodeTree
							id = {sentence.id}
							sentence1 = {sentence.sentence.locale1}
							sentence2 = {sentence.sentence.locale2}
							changeNode = {changeNode}
							playSentence = {playSentence}	
							isLeaf = {sentence.isLeaf}
							showTranslation = {sentence.showTranslation}
							currentSpeaker = {props.currentSpeaker}
							localePatient={props.localePatient}
							localeHcw={props.localeHcw}
						>
						</NodeTree>      
						))
					: 
						<div className="text-center mb-4">
							<Spinner animation="border" size='sm' />
						</div> 
					}
				</Card.Text>
			</Card.Body>
		</Card>

      	<Card className='card-standard hide'>
			<Card.Body>
			<Card.Text>
				<Row>
					<Col>
						<strong>automatic alternate: </strong>
						<label>{props.alternate ? 'Yes' : 'NO'}</label>
					</Col>
					<Col>
						<strong>locale1: </strong>
						<label>{props.localeHcw}</label>
					</Col>
					<Col>
						<strong>locale2: </strong>
						<label>{props.localePatient}</label>
					</Col>
				</Row>
			</Card.Text>
			</Card.Body>
      	</Card>
		<ReactPlayer className="react-player" playing 
			controls={false} 
			url={nodePlayURL} 
		/>
    </div>
  )

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
