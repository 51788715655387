import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import axios from 'axios'
import imgDoctor from '../../assets/images/doctor.png';
import imgPatient from '../../assets/images/patient.png';
import { useTranslation } from "react-i18next";
import { setLocaleHcw, setLocalePatient, setAlternate, setCurrentNodeId, setErrorMessage } from "../../js/actions/index";
import { ACCESS_TOKEN_NAME, API_LOCALES_APPTREE} from '../../js/constants/apiConstants';
import { LOADING_STATUS_START, LOADING_STATUS_STARTED, LOADING_STATUS_ENDED, LOADING_STATUS_ERROR } from '../../js/constants/apiConstants';
import { Card, Row, Col, Form, Image, Button, Spinner } from 'react-bootstrap';

const mapStateToProps = state => {
  return {
    localeHcw: state.localeHcw,
    localePatient: state.localePatient,
    alternate: state.alternate,
    appTree: state.appTree
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setLocaleHcw, setLocalePatient, setAlternate, setCurrentNodeId, setErrorMessage }, dispatch)
}

function LocaleSelector(props) {

  const { t } = useTranslation('common');
  const [localesSource, setLocalesSource] = useState([])
  const [localesDestination, setLocalesDestination] = useState([])
  const notAssignedLocale = 'not-assigned'
  const [loadingStatus, setLoadingStatus] = useState(1)

	useEffect(() => {
    if (localesSource.length > 0 && !props.localeHcw){
      props.setLocaleHcw(localesSource[0].iso_code)
    }
    if (localesDestination.length > 0 && !props.localePatient){
      props.setLocalePatient(localesDestination[0].iso_code)
    }
		if (loadingStatus === LOADING_STATUS_START){
			setLoadingStatus(LOADING_STATUS_STARTED)
      var url = process.env.REACT_APP_API_URL + API_LOCALES_APPTREE + '?appTreeId=' + props.appTree
      var urlSource = url + '&type=source'
      var urlDestination = url + '&type=destination'
      var config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) }}

      axios.all([
        axios.get(urlSource, config), 
        axios.get(urlDestination, config)
      ])
      .then(axios.spread((response1, response2) => {
        // Both requests are now complete
        if (response1.status === 200 && response2.status === 200) {
            setLocalesSource(response1.data.data.items)
            setLocalesDestination(response2.data.data.items)
            setLoadingStatus(LOADING_STATUS_ENDED)
        }else{
          setLoadingStatus(LOADING_STATUS_ERROR)
          props.setErrorMessage(t('api.generic_error'))
				}

      }));
		}	
	})

  function setLangs(e) {
    if (props.localeHcw && props.localePatient) {
      // clean previous nodes selected (for example in a search)
		  props.setCurrentNodeId(null)
      props.history.push('/main')
    }
  }

  function handleChangeLangHcw(e) {
    props.setLocaleHcw(e.target.value)
  }

  function handleChangeLangPatient(e) {
    props.setLocalePatient(e.target.value)
  }

  function handleChangeAlternante(e) {
    props.setAlternate(e.target.checked)
  }

  return (
    <div className="mt-2">
      <Card className='card-mini'>
        <Card.Body>
          <Card.Title className="mb-4 text-center">
            <strong>{t('app.select_languages')}:</strong>
          </Card.Title>
          <Card.Text>
            <Form>
              <Row>
                <Col xs={4}>
                  <div className="text-center mb-4">
                    <Image src={imgDoctor} roundedCircle />                    
                  </div>
                </Col>
                <Col>
                  <Form.Group controlId="langForm.langHcw">
                    <Form.Label>{t('app.hcw')}</Form.Label>
                    { loadingStatus === LOADING_STATUS_ENDED ? 
                        <Form.Control as="select" onChange={handleChangeLangHcw} defaultValue={props.localeHcw}>
                        <option disabled value={notAssignedLocale}>{t('app.select_language')}</option>
                          {localesSource.map((locale) => (
                            <option key={locale.iso_code} value={locale.iso_code}>{locale.name}</option>
                          ))}
                        </Form.Control>
                      : 
                      <div className="text-center mb-4">
                        <Spinner animation="border" size='sm' />
                      </div> 
                    }
                    
                    
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={4}>
                  <div className="text-center mb-4">
                    <Image src={imgPatient} roundedCircle />
                  </div>
                </Col>
                <Col>
                  <Form.Group controlId="langForm.langPatient">
                    <Form.Label>{t('app.patient')}</Form.Label>
                    { loadingStatus === LOADING_STATUS_ENDED ?
                      <Form.Control as="select" onChange={handleChangeLangPatient} defaultValue={props.localePatient}>
                        <option disabled value={notAssignedLocale}>{t('app.select_language')}</option>
                          {localesDestination.map((locale) => (
                            <option key={locale.iso_code} value={locale.iso_code}>{locale.name}</option>
                          ))}
                      </Form.Control>
                    : 
                    <div className="text-center mb-4">
                      <Spinner animation="border" size='sm' />
                    </div> 
                    }
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="alternate-selector hide">
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" checked="checked" onChange={handleChangeAlternante}/>
                    </Form.Group>
                    <span>{t('app.change_alternancy')}</span>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-center">
                    <Button 
                      onClick={setLangs} 
                      variant="outline-primary" 
                      block
                      disabled={loadingStatus !== LOADING_STATUS_ENDED}
                    >{t('app.ok')}</Button>
                  </div>
                </Col>
              </Row>

            </Form>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocaleSelector));
