import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { setCurrentNodeId } from "../../js/actions/index";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setCurrentNodeId }, dispatch)
}

function SearchElement(props){
	
	function setNode(newId, isSentence) {
		if (!isSentence){
			props.setCurrentNodeId(newId)
			props.history.push('/main')
		}
	}

	return (
		<div className='py-2 my-1 node-tree' onClick={ () => setNode(props.nodeId, props.isSentence)}>
			<Col>
				<Row>
					<Col>
						<div className="text-center">
							<span 
								className='sentence' 
							>{props.sentence}</span>
						</div>
					</Col>
				</Row>
				{props.isSentence &&
					<Row>
						<Col>
							<div className="text-center font-italic">
								<span 
									className='sentence' 
								>{props.sentence2}</span>
							</div>
						</Col>
					</Row>
				}
			</Col>
		</div>
	)
}

export default withRouter(connect(null, mapDispatchToProps)(SearchElement));
