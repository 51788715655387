import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { API_SENTENCE, ACCESS_TOKEN_NAME, } from '../../js/constants/apiConstants';
import { LOADING_STATUS_START, LOADING_STATUS_STARTED, LOADING_STATUS_ENDED, LOADING_STATUS_ERROR } from '../../js/constants/apiConstants';
import axios from 'axios'
import { bindActionCreators } from 'redux'
import { useTranslation } from "react-i18next";
import { setCurrentNodeId, setSearchString, setErrorMessage } from "../../js/actions/index";
import SearchCard from './SearchCard'

const mapStateToProps = state => {
	return {
		localeHcw: state.localeHcw,
		localePatient: state.localePatient,
		searchString: state.searchString,
		currentNodeId: state.currentNodeId,
		appTree: state.appTree,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setCurrentNodeId, setSearchString, setErrorMessage }, dispatch)
}

function Search(props){
	const { t } = useTranslation('common');
	const [loadingStatus, setLoadingStatus] = useState(LOADING_STATUS_START)
	const [sentences, setSentences] = useState([])
	const [searchStringSearched, setSearchStringSearched] = useState(null)

	useEffect(() => {
		if (props.searchString !== null) {
			setLoadingStatus(LOADING_STATUS_STARTED)
			setSearchStringSearched(props.searchString)
			var url = process.env.REACT_APP_API_URL + API_SENTENCE
				+ '?sentence=' + props.searchString
				+ '&locale-code-1=' + props.localeHcw
				+ '&locale-code-2=' + props.localePatient
				+ '&tree-id=' + '16'
				var config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }
			axios.get(url, config)
				.then(function (response) {
					props.setSearchString(null)
					if (response.status === 200) {
						console.log(response.data.data)
						setSentences(response.data.data.values)
						setLoadingStatus(LOADING_STATUS_ENDED)
					} else {
						setLoadingStatus(LOADING_STATUS_ERROR)
					}
				})
				.catch(function (error) {
					props.setSearchString(null)
					setLoadingStatus(LOADING_STATUS_ERROR)
					// console.log('error' + error)
					props.setErrorMessage(t('api.generic_error'))
				});
		}
	})

	if (!props.localeHcw || !props.localePatient) {
		props.history.push('/home')
		return null
	}

	return (
		<div className="mt-2">
			<SearchCard 
				title={t('search.nodes_for') + " '" + searchStringSearched + "'"} 
				showMenus={true}
				showSentences={false}
				sentences={sentences} 
				readyToDisplay={loadingStatus === LOADING_STATUS_ENDED}
				appTree={props.appTree}
				showCurrent={true}
			/>

			<SearchCard 
				title={t('search.sentences_for') + " '" + searchStringSearched + "'"} 
				showMenus={false}
				showSentences={true}
				sentences={sentences} 
				readyToDisplay={loadingStatus === LOADING_STATUS_ENDED}
				appTree={props.appTree}
				showCurrent={true}
			/>

			<SearchCard 
				title={t('search.nodes_other_apps_for') + " '" + searchStringSearched + "'"} 
				showMenus={true}
				showSentences={false}
				sentences={sentences} 
				readyToDisplay={loadingStatus === LOADING_STATUS_ENDED}
				appTree={props.appTree}
				showCurrent={false}
			/>

			<SearchCard 
				title={t('search.sentences_other_apps_for') + " '" + searchStringSearched + "'"} 
				showMenus={false}
				showSentences={true}
				sentences={sentences} 
				readyToDisplay={loadingStatus === LOADING_STATUS_ENDED}
				appTree={props.appTree}
				showCurrent={false}
			/>

			</div>
	)

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
