import React from 'react';
import { withRouter } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { setCurrentSpeaker, setAlternate, setCurrentNodeId, setForceChangeSpeaker } from "../../js/actions/index";
import { Row, Col, Image } from 'react-bootstrap';
import { CURRENT_SPEAKER_HCW, CURRENT_SPEAKER_PATIENT } from '../../js/constants/appConstants';
import { useTranslation } from "react-i18next";

import imgDoctor from '../../assets/images/doctor.png';
import imgPatient from '../../assets/images/patient.png';

const mapStateToProps = state => {
    return {
        currentSpeaker: state.currentSpeaker,
        alternate: state.alternate,
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCurrentSpeaker, setAlternate, setCurrentNodeId, setForceChangeSpeaker }, dispatch)
}

function Footer(props) {

    const { t } = useTranslation('common');
    const location = useLocation();
    if (location.pathname !== '/main') return('');
 
    function handleCurrentSpeakerHcw() {
        if (props.currentSpeaker !== CURRENT_SPEAKER_HCW){
            props.setForceChangeSpeaker(true)
        }
    }

    function handleCurrentSpeakerPatient() {
        if (props.currentSpeaker !== CURRENT_SPEAKER_PATIENT){
            props.setForceChangeSpeaker(true)
        }
    }

    return(
        <div id="footer" className="bg-white">
            <Row>
                <Col xs={6} className={"button-speaker " + ((props.currentSpeaker === CURRENT_SPEAKER_HCW) ? 'current-speaker' : '')} onClick={handleCurrentSpeakerHcw}>
                    <Row>
                        <Col xs={4} sm={2}>
                            <Image src={imgDoctor} />
                        </Col>
                        <Col className='footer-text' xs={8} sm={10}>
                            <span>{t('app.hcw')}</span>
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} className={"button-speaker " + ((props.currentSpeaker === CURRENT_SPEAKER_PATIENT) ? 'current-speaker' : '')} onClick={handleCurrentSpeakerPatient}>
                    <Row>
                        <Col xs={4} sm={2}>
                            <Image src={imgPatient} />
                        </Col>
                        <Col className='footer-text' xs={8} sm={10}>
                            <span>{t('app.patient')}</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));