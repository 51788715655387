import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setErrorMessage } from "../../js/actions/index";

const mapStateToProps = state => {
	return {
        errorMessage: state.errorMessage
	};
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setErrorMessage}, dispatch)
}

function ToastComponent(props) {
    const notify = () => toast.error(props.errorMessage, { 
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    useEffect(() => {
        if(props.errorMessage !== null) {
            notify()
            props.setErrorMessage(null)
        }
    });

    return (
        <ToastContainer/>
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ToastComponent));
