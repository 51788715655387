import React, { useState } from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import LoginForm from './components/LoginForm/LoginForm';
import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import Home from './components/Home/Home';
import Main from './components/Main/Main';
import Search from './components/Search/Search';
import PrivateRoute from './utils/PrivateRoute';
import ToastComponent from './components/ToastComponent/ToastComponent'; 
import AutoLogin from './components/Autologin/AutoLogin';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.scss';

function App() {
  const [title, updateTitle] = useState();
  const [errorMessage, updateErrorMessage] = useState();
  return (
    <Router>
    <div className="App">
      <Header title={title}/>
        <div className="container d-flex align-items-center flex-column">
          <Switch>
            <Route path="/" exact={true}>
              <AutoLogin/>
            </Route>
            <Route path="/acces/:token">
              <AutoLogin />
            </Route>
            <Route path="/login">
              <LoginForm showError={updateErrorMessage} updateTitle={updateTitle} />
            </Route>
            <Route path="/register">
              <RegistrationForm showError={updateErrorMessage} updateTitle={updateTitle}/>
            </Route>
            <PrivateRoute path="/home">
              <Home/>
            </PrivateRoute>
            <PrivateRoute path="/main">
              <Main />
            </PrivateRoute>
            <PrivateRoute path="/search">
              <Search />
            </PrivateRoute>
          </Switch>
          <ToastComponent/>
        </div>
        <Footer/>
    </div>
    </Router>
  );
}

export default App;
