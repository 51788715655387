import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SearchElement from './SearchElement'
import { Card, Spinner } from 'react-bootstrap';

function SearchCard(props){

	function SearchLine(props){
		return (
			props.sentences.map((sentence) => (
				((!sentence.isReference && props.showMenus) || (sentence.isReference && props.showSentences)) &&
				((props.appTree !== sentence.appTreeId && !props.showCurrent) || (props.appTree == sentence.appTreeId && props.showCurrent)) &&
					<SearchElement 
						sentence={sentence.sentence1} 
						sentence2={sentence.sentence2} 
						nodeId={sentence.nodeId}
						isSentence={props.showSentences}
					/> 
			))
		)	
	}

	return (
		<Card className='my-4' style={{ width: '700px' }}>
			<Card.Body>
				<Card.Title className="mb-4 text-center">
					<strong>{props.title}</strong>
				</Card.Title>
				<Card.Text>
					{	props.readyToDisplay ?
							<SearchLine 
								sentences={props.sentences} 
								showMenus={props.showMenus}
								showSentences={props.showSentences}
								appTree={props.appTree}
								showCurrent={props.showCurrent}
							/>
						: 
							<div className="text-center mb-4">
								<Spinner animation="border" size='sm' />
							</div> 
					}
				</Card.Text>
			</Card.Body>
		</Card>
	)
}

export default withRouter(SearchCard);
