import React, { useState } from 'react';
import axios from 'axios';
import './LoginForm.css';
import { API_LOGIN, ACCESS_TOKEN_NAME } from '../../js/constants/apiConstants';
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setErrorMessage, setAppTree } from "../../js/actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setErrorMessage, setAppTree }, dispatch)
}

function LoginForm(props) {
    const { t, i18n } = useTranslation('common');
    const [message, setMessage] = useState(null)
    const [state, setState] = useState({
        email: "",
        password: "",
    })
    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

    const handleSubmitClick = (e) => {
        e.preventDefault();
        const payload = JSON.stringify({
            "email": state.email,
            "password": state.password,
        })

        var config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            withCredentials: false
        }

        var url = process.env.REACT_APP_API_URL + API_LOGIN
        axios.post(url, payload, config)
            .then(function (response) {
                if (response.status === 200) {
                    localStorage.setItem(ACCESS_TOKEN_NAME, response.data.data.access_token);
                    if (response.data.data.apps.length > 0 && response.data.data.apps[0].trees.length >0){
                        props.setAppTree(response.data.data.apps[0].trees[0].id)
                        redirectToHome();
                    }else{
                        setMessage(t('auth.no_tree_available'))
                    }
                }
                else if (response.code === 204) {
                    props.setErrorMessage(t('auth.login_error_upnm'));
                }
                else {
                    props.setErrorMessage(t('auth.login_error_unex'));
                }
            })
            .catch(function (error) {
                // console.log(error);
                props.setErrorMessage(t('auth.login_error_unex'));
            });

    }
    const redirectToHome = () => {
        props.updateTitle('Home')
        props.history.push('/home');
    }
    const redirectToRegister = () => {
        props.history.push('/register');
        props.updateTitle('Register');
    }
    return (
        <div className="card col-12 col-lg-6 login-card mt-2 hv-center pt-4">
            <form>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputEmail1">{t('auth.email_address')}</label>
                    <input type="email"
                        className="form-control"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder={t('auth.enter_email')}
                        value={state.email}
                        onChange={handleChange}
                    />
                    <small id="emailHelp" className="form-text text-muted">{t('auth.never_share_email')}.</small>
                </div>
                <div className="form-group text-left">
                    <label htmlFor="exampleInputPassword1">{t('auth.password')}</label>
                    <input type="password"
                        className="form-control"
                        id="password"
                        placeholder={t('auth.password')}
                        value={state.password}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-check">
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >{t('auth.login')}</button>
            </form>
            <div className="alert alert-danger mt-4" style={{ display: message ? 'block' : 'none' }} role="alert">
                {message}
            </div>
            <div className="registerMessage d-none">
                <span>Dont have an account? </span>
                <span className="loginText" onClick={() => redirectToRegister()}>Register</span>
            </div>
        </div>
    )
}

export default withRouter(connect(null, mapDispatchToProps)(LoginForm));
