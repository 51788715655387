import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from "./js/store/index";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_es_ES from "./translations/es_ES/common.json";
import common_ca_ES from "./translations/ca_ES/common.json";
import common_ru_RU from "./translations/ru_RU/common.json";

i18next.init({
  interpolation: { escapeValue: false }, 
  lng: 'ca_ES',
  resources: {
    es_ES: { common: common_es_ES },
    ca_ES: { common: common_ca_ES },
    ru_RU: { common: common_ru_RU },
  },
});

ReactDOM.render(  
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
