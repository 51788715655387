import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, API_USER} from '../../js/constants/apiConstants';
import axios from 'axios'
import LocaleSelector from '../LocaleSelector/LocaleSelector';

function Home(props) {

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + API_USER, { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } })
      .then(function (response) {
        if (response.status !== 200) {
          redirectToLogin()
        }
      })
      .catch(function (error) {
        redirectToLogin()
      });
  })

  function redirectToLogin() {
    props.history.push('/login');
  }

  return (
    <div className="mt-2">
      <LocaleSelector/>
    </div>
  )

}

export default withRouter(Home);
