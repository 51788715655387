import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { CURRENT_SPEAKER_HCW, CURRENT_SPEAKER_PATIENT } from '../../js/constants/appConstants';

function NodeTree(props) {

	var node1SentenceId = props.sentence1.id
	var node1Value = props.sentence1.value ?? '-'
	var node2SentenceId = props.sentence2.id
	var node2Value = props.sentence2.value ?? '-'
	if (props.currentSpeaker !== CURRENT_SPEAKER_HCW) {
		var node1SentenceId = props.sentence2.id
		var node1Value = props.sentence2.value ?? '-'
		var node2SentenceId = props.sentence1.id
		var node2Value = props.sentence1.value ?? '-'
	}
	var switchAlternate = props.showTranslation

	var playSentence = node2SentenceId
	if (!props.showTranslation) playSentence = null

	return (
		<div className='py-2 my-1 node-tree' onClick={() => props.changeNode(props.id, props.isLeaf, switchAlternate, playSentence)}>
			<Col>
				<Row>
					<Col>
						<div className="text-center">
							<span className='sentence'>{node1Value}</span>
						</div>
					</Col>
				</Row>
				{props.showTranslation &&
					<Row>
						<Col>
							<div className="text-center font-italic">
								<span className='sentence'>{node2Value}</span>
							</div>
						</Col>
					</Row>
				}
			</Col>
		</div>
	)
}

export default withRouter(NodeTree);
